export default {
  RESET: "Reset",
  SEARCH: "Search",
  STATUS: "Status",
  NAME: "Name",
  DESC: "Desc.",
  USERNAME: "Username",
  PHONE: "Phone",
  PASSWORD: "Password",
  ACTION: "Action",
  EDIT: "Edit",
  ADD: "Add",
  DELETE: "Delete",
  DETAIL: "Detail",
  ROLE: "Role",
  TIME: "Time",
  REMARK: "Remark",
  LOCATION: "Location",
  ACCOUNT: "Account",
  AVATAR: "Avatar",
  NICKNAME: "Nickname",
  PROFILE: "Profile",
  SAVE: "Save",
  RESET_PASSWORD: "Reset Password",
  THEME_SETTING: "Theme Setting",
  LOG_OUT: "Log out",
  SECURITY: "Security",
  SECURITY_SETTING: "Security Setting",
  SITE_SETTING: "Site Setting",
  SITE_TITLE: "Site Title",
  SITE_LOGO: "Site Logo",
  OPEN: "Open",
  CLOSE: "Close",
  DICT_SETTING: "Dict Setting",
  NEW: "New",
  CREATED_AT: "Created At",

  SUPPLY: "Supply",
  CANTEEN: "Canteen",
  DELIVERY_DATE: "Delivery Date",
  TRIP: "Trip",
  ORDER_NO: "Order No",
  INVOICE_NO: "Invoice No",
  PRODUCT_COUNT: "Product Count",
  AMOUNT: "Amount",
  ORDER_DETAIL: "Order Detail",
  INVOICE_DETAIL: "Invoice Detail",
  PRODUCT_NAME: "Product Name",
  PRICE: "Price",
  ORDER_QUANTITY: "Order Quantity",
  ORDER_REMARK: "Order Remark",
  PACK_QUANTITY: "Pack Quantity",
  PACK_REMARK: "Pack Remark",

  // 订单模块
  操作: "Action",
  确定删除该产品吗: "Are you sure you want to delete this product",
  删除: "Delete",
  订单详情: "Order Detail",
  保存: "Save",
  基本信息: "Basic Info",
  订单状态: "Order Status",
  取消原因: "Cancel Reason",
  请输入取消原因: "Please enter the reason for cancellation",
  配送日期: "Delivery Date",
  餐厅: "Canteen",
  订单备注: "Order Remark",
  下单信息: "Order Info",
  下单员: "Orderer",
  下单时间: "Order Time",
  订单类型: "Order Type",
  配单信息: "Packaged Info",
  配单员: "Packager",
  开始时间: "Start Time",
  结束时间: "End Time",
  配送信息: "Delivered Info",
  车次: "Trip",
  配送司机: "Driver",
  送达时间: "Delivered Time",
  送货凭据: "Delivered Images",
  其他: "Others",
  发票号: "Invoice No",
  产品信息: "Product Info",
  添加产品: "Add Product",
  该产品已存在: "This product already exists",
  搜索产品: "Search Product",
  请填写取消原因: "Please fill in the reason for cancellation",
  请选择配送日期: "Please select the delivery date",
  请选择车次: "Please select the trip",
  类型: "Type",
  产品数: "Product Count",
  产品实配数: "Packaged Count",
  配单开始时间: "Packaged Start Time",
  配单结束时间: "Packaged End Time",
  查看订单: "Order Detail",
  总餐厅数目: "Total Canteen Count",
  来单餐厅数: "Ordered Canteen Count",
  总订单数: "Total Order Count",
  未配货订单: "Unpackaged Order Count",
  配货中订单: "Packaging Order Count",
  已打包订单: "Packaged Order Count",
  已送达订单: "Delivered Order Count",
  产品: "Product",
  订单数量: "Order Quantity",
  下单量: "Order Quantity",
  实配量: "Packaged Quantity",
  仓库: "Supply",
  总产品数: "Total Product Count",
  总产品金额: "Subtotal",
  配单量: "Packaged Quantity",
  订单号: "Order No",

  // 产品模块
  配单排序: "Package Sort",
  图片: "Image",
  "名称/描述": "Name/Desc",
  上架: "On Sale",
  下架: "Off Sale",
  名称: "Name",
  描述: "Desc",
  价格: "Price",
  分类: "Category",
  库存: "Stock",
  配单顺序: "Package Sort",
  状态: "Status",
  创建时间: "Created At",
  // 产品信息: "Product Info",
  导出数据: "Export Data",
  新增: "New",
  搜索: "Search",
  编辑: "Edit",
  请输入名称: "Please enter the name",
  请选择分类: "Please select the category",
  产品详情: "Product Detail",
  价格信息: "Price Info",
  添加价格: "Add Price",
  暂无数据: "No Data",
  操作前库存: "Stock Before",
  库存变化: "Stock Change",
  产品库存: "Product Stock",
  操作后库存: "Stock After",
  创建人: "Creator",
  查看详情: "Detail",
  进价: "Cost",

  // 分类模块
  序号: "No.",
  分类排序: "Category Sort",

  // 客户模版。餐厅
  用户名: "Username",
  密码: "Password",
  重置: "Reset",
  联系人: "Contact",
  电话: "Phone",
  物流别名: "Logistics Alias",
  地址: "Delivery Address",
  邮编: "Postcode",
  结账周期: "Billing Term",
  合作中: "Active",
  暂停合作: "Inactive",
  餐厅信息: "Canteen Info",
  重置密码: "Reset Password",
  请输入新的密码: "Please enter the new password",
  请输入餐厅名称: "Please enter the canteen name",
  请输入电话: "Please enter the phone",
  请输入用户名: "Please enter the username",
  请输入地址: "Please enter the address",
  请输入邮编: "Please enter the postcode",
  请选择结账周期: "Please select the billing term",
  "输入有效用户名，仅含字母、数字、下划线、感叹号":
    "Enter a valid username, containing only letters, numbers, underscores, and exclamation points",
  餐厅详情: "Canteen Detail",
  客户备注: "Canteen Remark",
  物流信息: "Logistics Info",
  发票抬头: "Invoice Title",
  同名称: "Same as Name",
  发票地址: "Invoice Address",
  送货地点: "Delivery Address",

  请先选择餐厅: "Please select the canteen first",

  // priceinfo
  产品名称: "Product Name",
  产品统一价格: "Product Price",
  产品议价: "Product Price",

  // tripinfo
  司机: "Driver",
  排序: "Sort",
  餐厅数: "Canteen Count",
  暂无餐厅: "No Canteen",
  "车次内餐厅排序（合作中）": "Canteen Sort (Active)",

  // 员工绩效
  下单绩效: "Order Performance",
  配单绩效: "Packaging Performance",
  员工名称: "Staff Name",
  抄单天数: "Order Days",
  订单总数: "Total Order",
  产品总数: "Total Product",
  日期: "Date",
  订单数: "Order Count",
  // 订单总数: "Total Order",
  配单天数: "Packaging Days",
  产品实际配总数: "Total Packaged Product",

  // DATA_ANALYSIS
  请选择餐厅和月份: "Please select the canteen and month",
  导出成功: "Export Success",
  "文件格式错误，导出失败": "File format error, export failed",
  月结单: "Monthly Statement",
  导出: "Export",
  月份: "Month",

  // market
  全局参数: "Global Parameter",

  // 其他
  点击或者拖拽图片上传: "Click or drag to upload image",
  "支持扩展名：.jpg .png .jpeg": "Support extensions: .jpg .png .jpeg",

  // dashboard
  客户数: "Canteens",
  今日订单: "Today's Orders",
  待处理订单: "Pending Orders",
  已送达订单_1: "Delivered Orders",
  库存预警: "Stock Alert",
  产品分类: "Category",
  商品名称: "Product Name",

  产品销量: "Product Sales",
  默认全选: "Default All",
}
